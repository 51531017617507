import { OrderDirection } from '../../common/enums/sort-direction.enum';
import { PaginatedEntity } from '../../common/interfaces/paginated-entity.interface';
import { IApplicationRequest } from '../../pages/requests/interfaces/Request.interface';

export const applicationRequestsSlice = (set: any) => ({
  applicationRequests: {
    items: [],
    meta: {
      currentPage: 1,
      itemCount: 0,
      itemsPerPage: 10,
      totalItems: 0,
      totalPages: 1,
      orderByColumn: 'createdOn',
      orderDirection: OrderDirection.ASC,
    },
  },
  setApplicationRequests: (applicationRequests: PaginatedEntity<IApplicationRequest>) => {
    set({ applicationRequests });
  },
});

export default { applicationRequestsSlice };
